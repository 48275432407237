.custom-navbar{
    .navbar-toggler{
        // margin-right: -15px;
        position: relative;
        outline: none;
        border: none;
        width: 25px; height: 14px;
        border-top: solid 2px white;
        border-bottom: solid 2px white;
        padding: 0;
        .navbar-toggler-icon{
            position: absolute;
            background: none;
            width: 100%;
            height: 2px;
            background-color: white;
            display: block;
            top: 50%;
            margin-top: -1px;
        }
    }
    .navbar-nav{
        margin-top: rem-calc(20px);
        .nav-item{
            display: flex;
            align-items: center;
            padding: rem-calc(10px) 0;
            justify-content: space-between;
            &.active .nav-link{
                font-weight: $font-weight-bold;
            }
            .nav-link{
                font-size: rem-calc(16px);
                color: white;
                padding: 0;
                margin: 0;
                letter-spacing: 0.1em;
            }
            .social{
                background-color: white;
                padding: 0;
                margin-left: 15px;
                width: rem-calc(19px);
                height: rem-calc(19px);
                display: flex;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                svg{
                    position: relative;
                    width: 13px;
                    height: 13px;
                    display: block;
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .custom-navbar{
        flex-direction: column;
        align-items: flex-start;
        .navbar-nav{
            margin-top: rem-calc(25px);
            .nav-item{
                padding: 0;
                .nav-link{
                    margin-right: rem-calc(15px);
                    font-size: rem-calc(13px);
                }
                .social{
                    margin-left: 0;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .custom-navbar{
        flex-direction: row-reverse;
        align-items: flex-end;
        .navbar-nav{
            .nav-item{
                line-height: 0.5rem;
                align-items: flex-end;
                .nav-link{
                    font-size: rem-calc(13px);
                    margin-right: rem-calc(23px);
                }
                .social{
                    margin-bottom: rem-calc(-4px);
                }
            }
        }
    }
}