.modal-unidad{
    .caracteristicas{
        font-size: rem-calc(12px);
        ul{
            padding-left: rem-calc(15px);
        }
    }
    &-ubicacion{
        overflow: visible;
        &:after{
            content: '';
            display: block;
            width: rem-calc(20px); height: rem-calc(20px);
            position: absolute;
            top: 0; right: rem-calc(-31px);
            background-image: url('/images/modal/misc.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        img{
            position: absolute;
            width: 100%; height: auto;
            top: 0; left: 0;
        }
    }
}

.modal-unidad-slide-in{
    &.mfp-bg{
        transition: opacity 0.3s;
        opacity: 0;
        &.mfp-ready{
            opacity: 1;
        }
        &.mfp-removing{
            opacity: 0;
        }
    }
    &.mfp-wrap{
        .mfp-close{
            transition: all 0.5s cubic-bezier(0, 0, 0, 1);
            opacity: 0;       
        }
        .mfp-container{
            transition: all 0.5s cubic-bezier(0, 0, 0, 1);
            transform: translate3d(0, 100px, 0);
            opacity: 0;
            will-change: transform;
        }
        &.mfp-ready{
            .mfp-close{
                opacity: 1;       
            }
            .mfp-container{
                transform: translate3d(0,0,0);
                opacity: 1;
            }
        }
        &.mfp-removing{
            .mfp-close{
                opacity: 0;       
            }
            .mfp-container{
                transition: all 0.2s ease-in;
                transform: translate3d(0, 100px, 0);
                opacity: 0;
            }
        }
    }
    .mfp-content{
        margin-top: rem-calc(63px);
        background-color: white;
        min-height: calc(100vh - #{rem-calc(63px)});
    }
    @include media-breakpoint-up(md){
        .mfp-content{
            margin-top: rem-calc(83px);
            min-height: calc(100vh - #{rem-calc(83px)});
        }
    }
    .mfp-container{
        padding: 0;
    }
}