@function rem-calc($size) { 
	$remSize: $size / 16px; 
	@return #{$remSize}rem; 
}

// Bootstrap Required
@import "node_modules/bootstrap/scss/functions";
@import "variables";
@import "node_modules/bootstrap/scss/mixins";

// Bootstrap Optional
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
//@import "node_modules/bootstrap/scss/button-group";
//@import "node_modules/bootstrap/scss/input-group";
//@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
//@import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/pagination";
//@import "node_modules/bootstrap/scss/badge";
//@import "node_modules/bootstrap/scss/jumbotron";
// @import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/media";
// @import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/utilities";
// @import "node_modules/bootstrap/scss/print";

@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/magnific-popup/src/css/settings";
@import "node_modules/magnific-popup/src/css/main";
@import "photoswipe/main.scss";
@import "photoswipe/default-skin/default-skin.scss";

body{
    -webkit-font-smoothing: antialiased;
    padding-top: rem-calc(64px);
    min-height: 100vh;
    position: relative;
}
@include media-breakpoint-up(md){
    body{
        padding-top: rem-calc(116px);
    }
}
@include media-breakpoint-up(lg){
    body{
        padding-top: rem-calc(83px);
        padding-bottom: rem-calc(70px);
    }
}

p.small{
    line-height: 1.6em;
}

img.lazysizes{
    opacity: 0;
}
img.lazysizes.lazyloaded{
    transition: opacity 1s;
    opacity: 1;
}

.object-fill{
    position: absolute;
    z-index: 3;
    top: 0; left: 0;
    width: 100%; height: 100%;
    object-fit: cover;
}

.embed-responsive-3by2 {
    &::before {
        padding-top: percentage(2 / 3);
    }
}

@include media-breakpoint-up(md){
    .column-count-2{
        column-count: 2;
        column-gap: rem-calc(23px);
    }
}

@include media-breakpoint-up(lg){
    .column-count-lg-2{
        column-count: 2;
        column-gap: rem-calc(23px);
    }
}

@include media-breakpoint-down(md){
    .container{
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
    }
}

.js-open-photoswipe{
    cursor: pointer;
    @include media-breakpoint-down(md){
        &:after{
            content: '';
            width: rem-calc(35px); height: rem-calc(35px);
            display: block;
            background-color: $dark;
            position: absolute;
            top: 0; right: 0;
            z-index: 5;
            background-image: url('/images/localizacion/search-icon.svg');
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

button.mfp-close{
    width: rem-calc(30px);
    height: rem-calc(30px);
    line-height: rem-calc(30px);
    background-image: url(/images/modal/close.svg) !important;
    text-indent: -99999px;
    overflow: hidden;
    opacity: 1;
    right: 20px;
    top: rem-calc(17px);
    //left: 50%;
    //transform: translateX(-50%);
    //margin-left: calc( #{map-get($container-max-widths, sm )} / 2 - 35px );
    &:active{
        top: rem-calc(25px);
    }
}
@include media-breakpoint-up(sm){
    button.mfp-close{
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        margin-left: calc( #{map-get($container-max-widths, sm )} / 2 - 35px );
    }
}
@include media-breakpoint-up(md){
    button.mfp-close{
        top: rem-calc(25px);
        margin-left: calc( #{map-get($container-max-widths, md )} / 2 - 35px );
    }
}
@include media-breakpoint-up(lg){
    button.mfp-close{
        margin-left: calc( #{map-get($container-max-widths, lg )} / 2 - 25px );
    }
}
@include media-breakpoint-up(xl){
    button.mfp-close{
        margin-left: calc( #{map-get($container-max-widths, xl )} / 2 - 25px );
    }
}
.mfp-bg {
    background: $gray-800;
    opacity: 0.98;
}
.mfp-wrap{
    -webkit-overflow-scrolling: touch;
}

.pswp__button--close{
    position: absolute;
    right: auto;
    width: rem-calc(30px);
    height: rem-calc(30px);
    line-height: rem-calc(30px);
    background: url(/images/modal/close.svg) !important;
    text-indent: -99999px;
    overflow: hidden;
    opacity: 1;
    right: 20px;
    top: rem-calc(17px);
    z-index: 99;
}
@include media-breakpoint-up(sm){
    .pswp__button--close{
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        margin-left: calc( #{map-get($container-max-widths, sm )} / 2 - 35px );
    }
}
@include media-breakpoint-up(md){
    .pswp__button--close{
        top: rem-calc(25px);
        margin-left: calc( #{map-get($container-max-widths, md )} / 2 - 35px );
    }
}
@include media-breakpoint-up(lg){
    .pswp__button--close{
        margin-left: calc( #{map-get($container-max-widths, lg )} / 2 - 25px );
    }
}
@include media-breakpoint-up(xl){
    .pswp__button--close{
        margin-left: calc( #{map-get($container-max-widths, xl )} / 2 - 25px );
    }
}
.pswp__ui--fit .pswp__top-bar, 
.pswp__ui--fit .pswp__caption {
    background-color: rgba(0, 0, 0, 0);
}
.pswp__top-bar{
    height: 64px;
}

#google-map{
    position: relative;
    width: 100%; height: auto;
    background-color: $gray-200;
    &:before{
        content: '';
        display: block;
        height: 0; width: 100%;
        padding-bottom: 75%;
    }
}
@include media-breakpoint-up(md){
    #google-map{
        &:before{
            padding-bottom: 56%;
        }
    }
}
@include media-breakpoint-up(lg){
    #google-map{
        &:before{
            padding-bottom: 35%;
        }
    }
}

.mb-gutter{
    margin-bottom: $grid-gutter-width;
}


// Import Modules
@import "header";
@import "custom-navbar";
@import "footer";
@import "slider";
@import "hero-image";
@import "planta";
@import "modal-unidad";
@import "type";
@import "small-dialog";