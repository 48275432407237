#footer{
    position: relative;
    left: 0; bottom: 0;
    width: 100%;
    height: auto;
    color: white;
    background-color: $gray-800;
    padding-top: rem-calc(20px);
    padding-bottom: rem-calc(20px);
    .nav-link{
        padding: 0;
    }
    .logo-sh{
        width: rem-calc(71px);
    }
    .logo-ma{
        width: rem-calc(122px);
    }
    .logo-th{
        width: rem-calc(69px);
    }
    .logo-cp{
        width: rem-calc(186px);
    }
}

@include media-breakpoint-up(md){
    #footer{
        .nav-item{
            margin-left: rem-calc(40px);
        }
        .logo-cp{
            margin-bottom: rem-calc(-8px);
        }
    }
}

@include media-breakpoint-up(lg){
    #footer{
        position: absolute;
        height: rem-calc(70px);
    }
}