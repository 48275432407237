.planta{
    margin-top: 30px;
    &-plano{
        position: relative;
        background-size: contain;
        background-position: top left;
        background-repeat: no-repeat;
        @media screen and (min-aspect-ratio: 16/9) {
            width: 85%;
        }
        @media (min-width: 1440px) {
            width: 100%;
        }
        #animation_container{
            position: absolute;
            top: 0; left: 0;
            width: 100% !important; height: 100% !important;
            canvas{
                width: 100% !important;
                height: auto !important;
            }
        }
        &-leyenda{
            background-color: #333333;
            color: white;
            font-size: rem-calc(11px);
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            padding: rem-calc(8px) rem-calc(13px);
            line-height: 1.1em;
            transform: translate(-50%,-50%) scale(1);
            @media screen and (min-aspect-ratio: 16/9) {
                transform: translate(-50%,-50%) scale(0.9);
            }
        }
    }
    &-info{
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &-referencia{
        max-width: rem-calc(285px);
        align-self: flex-end;
    }
    &-tabla{
        margin: 0;
        font-size: rem-calc(12px);
        th, td{
            padding: rem-calc(6px) 0;
            border-top: none;
            border-bottom: solid 1px $dark !important;
            line-height: 1em;
        }
        th{
            border-top: solid 1px $dark !important;
        }
        .ref{
            display: flex;
            align-items: center;
            padding: rem-calc(20px) 0px;
            &:before{
                content: '';
                width: rem-calc(20px);
                height: rem-calc(20px);
                background-color: #E67373;
                display: block;
                margin-right: rem-calc(6px);
                opacity: 0.5;
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .planta{
        margin: 0;
        &:after{
            content: 'Para ver las unidades visite el site desde una computadora o tablet';
            display: block;
            position: fixed;
            top: 50%;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 25px;
            text-transform: uppercase;
            line-height: 1em;
            font-weight: 300;
            transform: translateY(-50%);
            padding: 25px;
        }
        & > .container{
            visibility: hidden;
        }
    }
}

#plantas-nav{
    position: fixed;
    z-index: 9;
    width: 100%;
    padding: 10px 0px;
    border-top: solid 1px #939393;
    .nav{
        .nav-item{
            padding-right: rem-calc(23px);
            &.active .nav-link{
                font-weight: bold;
            }
            .nav-link{
                letter-spacing: 0px;
                font-size: rem-calc(13px);
                padding: 0;
                line-height: rem-calc(8px);
                color: white;
            }
        }
    }
}

@include media-breakpoint-down(sm){
    #plantas-nav{
        display: none;
    }
}