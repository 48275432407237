#small-dialog {
	background: white;
	padding: 25px 25px;
	text-align: left;
	max-width: 450px;
	margin: 40px auto;
    position: relative;
    color: $dark;
    label{
        font-size: 14px;
    }
}

.mfp-bg.modal-small-dialog{
    background-color: $gray-800;
}