#header{
    position: fixed;
    z-index: 9;
    top: 0; left: 0;
    width: 100%;
    background-color: $gray-800;
    padding-top: rem-calc(12px);
    padding-bottom: rem-calc(12px);
    .logo{
        width: auto;
        height: rem-calc(40px);
    }
}
@include media-breakpoint-up(md) {
    #header{
        .logo{
            width: auto;
            height: rem-calc(50px);
        }
        &:before{
            content: '';
            display: block;
            position: absolute;
            width: 100%; height: 1px;
            bottom: 40px;
            left: 0;
            background-color: #939393;
        }
    }
}

@include media-breakpoint-up(lg) {
    #header{
        &:before{
            display: none;
        }
    }
}

@include media-breakpoint-up(lg) {
    #header{
        height: rem-calc(83px);
        padding-top: rem-calc(17px);
        padding-bottom: rem-calc(14px);
        .logo{
            height: rem-calc(52px);
        }
    }
}