$headerHeight: rem-calc(83px);

.slider{
    width: 100%; 
    height: 80vw;
    &.slick-slider .slick-track, 
    &.slick-slider .slick-list{
        height: 100%;
    }
    .slide{
        position: absolute;
        width: 100%; 
        height: 80vw;
        z-index: 0;
        overflow: hidden;
        &:first-of-type{
            z-index: 2;
        }
        .title{
            position: absolute;
            z-index: 3;
            bottom: 0; left: 0;
            color: white;
            text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);
            width: 100%; height: auto;
            margin: 0;
            background-color: $dark;
            height: 95px;
            display: flex;
            align-items: center;
            h1{
                font-size: rem-calc(24px);
                margin: 0;
            }
        }
        .object{
            position: absolute;
            z-index: 3;
            top: 0; left: 0;
            width: 100%; height:calc(100% - 95px);
            &.object-fit-cover{
                object-fit: cover;
            }
        }
        .object-blurred{
            background-color: $dark;
        }
    }
    &.-no-title{
        height: 56.25vw;
        .slide{
            height: 56.25vw;
            .object{
                height: 100%;
            }
        }
    }
}

@include media-breakpoint-up(md){
    .slider{
        height: 49vw !important;
        .slide{
            height: 49vw !important;
            .title{
                height: 100%;
                background-color: rgba(0, 0, 0, 0.15);
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                h1{
                    font-size: rem-calc(38px);
                }
            }
            .object{
                height: 100%;
            }
        }
    }
}

@include media-breakpoint-up(lg){
    .slider{
        height: calc(100vh - #{$headerHeight}) !important;
        .slide{
            height: calc(100vh - #{$headerHeight}) !important;
            .title{
                height: 100%;
                background-color: rgba(0, 0, 0, 0.15);
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                h1{
                    font-size: rem-calc(48px);
                }
            }
        }
    }
}

@keyframes anim-arrow {
    0% {
    	transform: translate3d(0, -150%, 0);
    	opacity: 0;
    }
    75% {
    	transform: translate3d(0,0%,0);
    	opacity: 1;
    }
    100%{
        transform: translate3d(0,0%,0);
    	opacity: 1;
    }
}

.flecha-dwn{
    position: absolute;
    bottom: 25px;
    left: calc(50% - 23px);
    animation-name: anim-arrow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 1023px) {
    .flecha-dwn{
        display: none;
    }
}