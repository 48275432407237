.hero-image{
    display: block;
    position: relative;
    width: 100%;
    &:before{
        content: '';
        width: 100%; height: 0;
        display: block;
        position: relative;
        padding-bottom: 56%;
        background-color: $gray-800;
        z-index: 1;
    }
    .object{
        position: absolute;
        z-index: 3;
        top: 0; left: 0;
        width: 100%; height: 100%;
    }
}

@include media-breakpoint-up(md){
    .hero-image{
        &:before{
            padding-bottom: 49%;
        }
    }
}

.object-blurred{
    display: block;
    position: absolute;
    z-index: 2;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-position: center;
    background-size: cover;
}

.object-fit-cover{
    object-fit: cover;
}